<template>
  <div
    :class="['channel-logo', { 'phantom': hasFailed || noImage }]">
    <picture>
      <source
        v-for="(picture, i) in pictures"
        :key="i"
        :srcset="picture.source"
        :media="picture.media">
      <img
        v-if="!noImage && !hasFailed"
        :src="imageUrl"
        :alt="alt"
        width="362px"
        height="263px"
        @error="onError()">
    </picture>
    <div v-if="noImage || hasFailed" class="phantom-image">
      <wptv-icon
        name="vod"
        :alt="alt"
        :aria-label="alt"
        style="height:50px; width:50px" />
    </div>
  </div>
</template>

<script>
import WptvIcon from '@wptv/components/ui/icon/Icon'

export default {
  name: 'ChannelLogo',
  components: {
    WptvIcon
  },
  props: {
    pictures: {
      type: Array,
      required: true
    },
    imageUrl: {
      type: String,
      required: true
    },
    imageAlt: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      hasFailed: false
    }
  },
  computed: {
    noImage () {
      return !this.imageUrl && (this.pictures && this.pictures.length === 0)
    },
    alt () {
      return this.$t('home.channelAccess', { channelName: this.imageAlt })
    }
  },
  methods: {
    onError () {
      this.hasFailed = true
    }
  }
}
</script>

<style lang="scss" scoped>
.channel-logo {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 101%;
  }

  picture {
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .phantom-image {
    position: absolute;
    display: flex;
    top: 0;
    height: 100%;
    width: 100%;
    background: #f0f0f0;
    align-items: center;
    justify-content: center;
  }
}
</style>
