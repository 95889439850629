<template>
  <div class="home">
    <div
      class="title padding"
      data-wptv="home-titre ">
      <h2 class="mb-0">
        {{ $t('home.homeTitle') }}
      </h2>
    </div>

    <ul
      v-if="channels && channels.length > 0"
      id="home-mosaic"
      :aria-label="$t('home.homeMosaicAriaLabel')"
      class="wptv-grid grid-side-gutters"
      data-wptv="home-mosaique">
      <li
        v-for="channel in channels"
        :key="channel.id"
        class="grid-item wptv-grid--item wptv-xxsmall-2 wptv-small-3 wptv-medium-4 wptv-large-6 wptv-xlarge-6"
        data-wptv="home-mosaique-item">
        <router-link
          v-if="channel.channelType !== 'EXTERNAL'"
          v-tealium:click="$stats.home.onClickChannelHome(channel.id)"
          :aria-label="channel.name"
          :to="{ name: 'Corner', params: { channelId: channel.id } }"
          class="home-mosaic-item">
          <channel-logo
            :pictures="getPictures(channel)"
            :image-url="getImage(channel)"
            :image-alt="channel.name" />
        </router-link>
        <a
          v-else
          v-tealium:click="$stats.home.onClickExternalChannel(channel.id)"
          :href="channel.partnerPortalLink"
          target="_blank"
          class="home-mosaic-item">
          <channel-logo
            :pictures="getPictures(channel)"
            :image-url="getImage(channel)"
            :image-alt="channel.name" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import ChannelLogo from '../shared/channelLogo/ChannelLogo'
import { setFocusOnFirstFocusableChild } from '@wptv/components/utils'
import { useChannelStore } from '../../store/channels/channelStore'
import { useConfigStore } from '../../store/config/configStore'
import { mapStores } from 'pinia'

export default {
  name: 'Home',
  components: {
    ChannelLogo
  },
  head () {
    return {
      title: this.$t('home.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('home.description')
        },
        { name: 'navik::pageType', content: 'home' }
      ]
    }
  },
  computed: {
    ...mapStores(useChannelStore, useConfigStore),
    channels () {
      return this.channelStore.channels
    }
  },
  mounted () {
    setFocusOnFirstFocusableChild(document.querySelector('#home-mosaic'))
    this.configStore.setH1({
      visible: this.$t('general.menu.replay'),
      hidden: this.$t('home.homeTitleAccessibility')
    })
  },
  methods: {
    // TODO utils (shared with carouselChaines)
    getPictures (channel) {
      return channel.logos.ref_4_3
        ? [
            {
              media: '(max-width:320px)',
              source: channel.logos.ref_4_3 + '?format=280x'
            },
            {
              media: '(max-width:480px)',
              source: channel.logos.ref_4_3 + '?format=364x'
            },
            {
              media: '(max-width:768px)',
              source: channel.logos.ref_4_3 + '?format=640x'
            },
            {
              media: '(max-width:1024px)',
              source: channel.logos.ref_4_3 + '?format=960x'
            }
          ]
        : []
    },
    getImage (channel) {
      return channel.logos.ref_4_3 || ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/vendors/_wptv-styles.scss";

.home {
  max-width: 90rem;
  margin: auto;
  @include mediaQueries('padding', (xsmall: 0 0.9375rem 0, medium: 0 0.75rem 0, large: 0 2rem 0, xlarge: 0 2.5rem 0));

  .title {
    @include mediaQueries('padding', (xsmall: 1.25rem 0 0 0, medium: 1.875rem 0 0 0));
  }

  .wptv-grid {
    padding: 0;
    margin-left:  -#{$mosaic-item-padding};
    margin-right: -#{$mosaic-item-padding};
    width: calc(100% + (#{$mosaic-item-padding} * 2));
  }

  .wptv-grid--item {
    padding: calc(1.875rem/2);
    .home-mosaic-item {
      display: block;
      position: relative;
      margin: 0;
      border: solid 0.0625rem #ddd;
      @include mediaQueries('padding', (xsmall:  1.438rem 2.063rem, small:  1.438rem 2.063rem, medium: 2.125rem 2.875rem, large: 1.438rem 2.063rem, xlarge: 1.938rem 2.813rem));

      /deep/ picture img {
        min-height: auto;
      }
    }
  }
}

</style>
